<template>
  <q-page class="q-pa-sm">
    <div class="row">
      <div class="col-12">
        <q-item>
          <q-input v-model="entity.header"
                   :rules="[val => $validators.required(val)]"
                   class="full-width"
                   clearable
                   dense
                   label="Название"
                   outlined/>
        </q-item>
      </div>

      <!--      <div class="col-6">-->
      <!--        <q-item>-->
      <!--          <q-input v-model="entity.download_url"-->
      <!--                   :rules="[val => !val || $validators.isValidUrl(val)]"-->
      <!--                   class="full-width"-->
      <!--                   clearable-->
      <!--                   dense-->
      <!--                   label="Ссылка"-->
      <!--                   outlined/>-->
      <!--        </q-item>-->
      <!--      </div>-->

      <div class="col-12">
        <q-item>
          <q-input v-model="entity.description" autogrow class="full-width" clearable dense
                   label="Описание"
                   outlined/>
        </q-item>
      </div>

      <div class="col-4">
        <q-item>
          <q-input v-model="entity.price"
                   :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']"
                   class="full-width"
                   dense
                   label="Сумма начисления"
                   outlined
                   type="number"
          >
            <template v-slot:prepend>
              <q-icon name="currency_ruble"/>
            </template>
          </q-input>
        </q-item>
      </div>

      <div class="col-4">
        <q-item>
          <q-input v-model="entity.activation_per_user"
                   :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']"
                   class="full-width"
                   dense
                   label="Кол-во считываний одним пользователем"
                   outlined
                   type="number"
          >
            <template v-slot:prepend>
              <q-icon name="person_add_alt"/>
            </template>
          </q-input>
        </q-item>
      </div>

      <div class="col-4">
        <q-item>
          <q-input v-model="entity.activation_per_code"
                   :rules="[val => $validators.positiveNumber(val) || 'Только положительное число']"
                   class="full-width"
                   dense
                   label="Кол-во считываний QR-кода"
                   outlined
                   type="number"
          >
            <template v-slot:prepend>
              <q-icon name="qr_code_scanner"/>
            </template>
          </q-input>
        </q-item>
      </div>
      <div class="col-12">
        <q-card bordered class="q-ma-md q-pa-md" flat>
          <span v-if="!entity.campaign_start" class="text-accent">
                Срок действия акции не указан
              </span>
          <span v-else>
                Срок действия акции: {{ $filters.toHumanDate(entity.campaign_start) }}
                -
                {{ $filters.toHumanDate(entity.campaign_end) }}
              </span>
          <q-btn class="q-ml-lg shadow-5" icon="edit" round size="sm">
            <q-popup-proxy cover transition-hide="scale" transition-show="scale"
                           @before-show="updateDateRange">
              <q-date v-model="dtRange" range>
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn v-close-popup color="primary" flat label="Отменить"/>
                  <q-btn v-close-popup color="primary" flat label="OK" @click="saveDateRange"/>
                </div>
              </q-date>
            </q-popup-proxy>
          </q-btn>
        </q-card>
      </div>
    </div>

    <div class="q-pa-md">
      <q-btn :loading="loading" class="q-mr-md"
             color="primary"
             icon="save"
             label="Сохранить"
             rounded
             @click="saveEntity"/>

      <q-btn class="q-mr-md"
             icon="cancel"
             label="Назад"
             rounded
             @click="$router.back()"/>
    </div>
  </q-page>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Notify } from 'quasar';
import { MarketApi } from '@/api/apis/market-api.ts';

export default defineComponent({
  components: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const entityId = ref(route.params.id);
    const entity = ref({
      header: '',
      description: '',
      download_url: '',
      price: '10',
      activation_per_user: 1,
      activation_per_code: 1,
      campaign_start: '2022-01-28T13:36:19.643Z',
      campaign_end: '2022-01-28T13:36:19.643Z',
    });

    if (entityId.value) {
      new MarketApi().campaignControllerGetCampaignList(0, 1, undefined, undefined, undefined, undefined, entityId.value)
        .then(({ data }) => {
          entity.value = data.data.pop();
        })
        .catch(() => entityId.value = 0);
    }

    const dtRange = ref({
      from: '2020/07/08',
      to: '2020/07/17',
    });

    const loading = ref(false);
    return {
      entity,
      entityId,
      dtRange,
      loading,

      updateDateRange() {
        dtRange.value = {
          from: entity.value.campaign_start,
          to: entity.value.campaign_end,
        };
      },

      saveDateRange() {
        entity.value.campaign_start = new Date(dtRange.value.from).toISOString();
        entity.value.campaign_end = new Date(dtRange.value.to).toISOString();
      },

      async saveEntity() {
        loading.value = true;

        try {
          delete entity.value.vendor_no;

          if (entityId.value) {
            await new MarketApi().campaignControllerUpdateCampaign({
              header: entity.value.header,
              description: entity.value.description,
              download_url: entity.value.download_url,
              price: entity.value.price,
              activation_per_user: parseInt(entity.value.activation_per_user),
              activation_per_code: parseInt(entity.value.activation_per_code),
              campaign_start: entity.value.campaign_start,
              campaign_end: entity.value.campaign_end,
              vendor_no: entity.value.vendor_no,
              campaign_id: entity.value.campaign_id,
            });
          } else {
            const { data } = await new MarketApi().campaignControllerCreateCampaign(entity.value);
            if (data?.campaign_id) {
              entity.value = data;
              entityId.value = data?.campaign_id;

              router.replace(`/campaigns/addedit/${data?.campaign_id}`);
            }
          }

          Notify.create({
            type: 'positive',
            message: 'Сохранено',
          });
        } catch (e) {
          console.error(e);
        }

        loading.value = false;
      },
    };
  },
});
</script>
